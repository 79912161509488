<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物请购</h3>
    <div class="detail">
      <h4 class="title">请购实验动物信息</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span>清洁等级：{{
            activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级'
          }}</span>
        <span>动物来源：{{ activeInfo.aniSupply.supplier }}</span>
        <span>许可证号：{{ activeInfo.aniSupply.prodCert }}</span>
        <p>
          <span>雌性周龄：{{ female.weekDays }}周</span>
          <span>雌性体重：{{ female.weight }}g</span>
          <span>雌性数量：{{ female.count }}</span>
        </p>
        <p>
          <span>雄性周龄：{{ male.weekDays }}周</span>
          <span>雄性体重：{{ male.weight }}g</span>
          <span>雄性数量：{{ male.count }}</span>
        </p>
      </div>
      <h4 class="title">采购方式</h4>
      <template v-if="status<6">
        <el-form ref="addForm" size="small" :model="purchaseWay" class="addForm " label-suffix=":"
                 label-width="80px">
          <h3 class="tit">采购方式</h3>
          <el-form-item label="采购方式">
            <el-radio-group v-model="purchaseWay.buyWay" class="radioGroup" @change="change">
              <el-radio :label="1">指定采购员</el-radio>
              <el-radio :label="2">指定角色</el-radio>
              <el-radio :label="3">指定部门</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采购员">
            <template v-if="purchaseWay.buyWay==1">
              <el-radio-group v-model="purchaseWay.user" class="radioGroup">
                <el-radio :label="ind" v-for="(item,ind) in userList" :key="item.userId"
                          @change="getAppId(item.userId,item.nickname)">
                  {{ item.nickname }}
                </el-radio>
              </el-radio-group>
            </template>
            <template v-else-if="purchaseWay.buyWay==2">
              <el-select v-model="purchaseWay.user" placeholder="请选择人员">
                <el-option v-for="item in roleList" :key="item.roleId" :label="item.name"
                           :value="item.roleId"></el-option>
              </el-select>
            </template>
            <template v-else>
              <a-tree-select
                  class="smallWidth"
                  :dropdownStyle="{ maxHeight: '180px', overflow: 'auto' }"
                  :treeData="partList"
                  placeholder='请选择部门'
                  treeDefaultExpandAll v-model="purchaseWay.user">
              </a-tree-select>
            </template>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="purchaseBtn" :disabled="disabled">确认请购</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <div class="desc">
          <p>采购方式：{{ activeInfo.aniBuy.buyWay == 1 ? '指定采购员' : activeInfo.aniBuy.buyWay == 2 ? '指定角色' : '指定部门' }}</p>
          <p>采购员：{{ activeInfo.aniBuy.personName }}</p>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "purchase",
  props: ['id', 'status'],
  data() {
    return {
      activeInfo: {
        aniSupply: {},
        aniBuy:{}
      },
      userList: [],
      roleList: [],
      partList: [],
      purchaseWay: {
        buyWay: 1,
        user: null,
        apprId: null,
        name: '',
      },
      disabled: false,
      // 雌性
      female: {},
      // 雄性
      male: {},
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.getType()
    this.getUser()
    if (this.status >= 6) {
      this.disabled = true
    }
  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/order/buy/" + this.id).then((res) => {
        if (res.status == 200) {
          that.activeInfo = res.data

          // 雌雄性
          res.data.aniOrderDetailList.forEach(obj => {
            if (obj.gender == 0) {
              this.female = obj
            } else {
              this.male = obj
            }
          })
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    change(val) {
      this.purchaseWay.user = null
      if (val == 1) {
        this.getUser()
      } else if (val == 2) {
        // 角色
        this.$get("/oauth/role/" + this.user.companyId).then(res => {
          this.roleList = res.data
        })
      } else {
        // 部门
        this.$get("/oauth/dept/tree").then(res => {
          this.partList = res.data.children;
        })
      }
    },
    getUser() {
      // 指定采购员
      this.$get("/oauth/user/personal/" + this.user.companyId).then(res => {
        if (res.status == 200) {
          this.userList = res.data
        }
      })
    },
    getAppId(id, name) {
      this.purchaseWay.apprId = id
      this.purchaseWay.name = name
    },
    purchaseBtn() {
      let params = {
        personId: this.purchaseWay.apprId,
        personName: this.purchaseWay.name,
        buyWay: this.purchaseWay.buyWay,
        subId: this.activeInfo.subId
      }
      this.$postJson('/subject/ani-buy', params).then(res => {
        this.$message.success("请购成功")
        this.$router.go(-1)
        this.disabled = true
      }).catch(err => {
        this.$message.error("请购失败")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.addForm .el-form-item:nth-child(2) {
  margin-bottom: 10px;
}
</style>
